<template>
  <div class="container">
    <!-- <div class="title">支付演示</div> -->
    <div class="title">Thanh toán Demo</div>
    <!-- <div class="hint">请按照支付演示视频步骤进行操作：</div> -->
    <div class="hint">Vui lòng làm theo các bước video demo thanh toán:</div>
    <div class="video-box" v-if="payment == 'ivnpay'">
      <video src="@/assets/pay/guide-ivnpay-momo.mp4" class="video" controls></video>
    </div>
    <div class="video-box" v-else-if="payment == 'aupay'">
      <video src="@/assets/pay/gudie-aupay-zalo.mp4" class="video" controls></video>
    </div>
    <div class="video-box" v-else>
      <!-- 暂无该支付方式的演示视频 -->
      <div class="no-video">
        <div class="text">Không có video giới thiệu cho phương thức thanh toán này.</div>
      </div>
    </div>
    <!-- <div class="hint">其他支付方式:</div> -->
    <div class="hint">Phương thức thanh toán khác:</div>
    <div class="btn-list">
      <div
        v-for="(_, name) in paynameMap" :key="name"
        @click="choose(name)"
        class="btn-lite"
        :class="{active: payname == name}"
      >
        <div>{{paynames[name].text}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      paynameMap: {
        momopay: 'ivnpay',
        zalopay: 'aupay',
        viettelpay: '',
        bankcard: ''
      },
      paynames: {
        momopay: { text: 'MoMo Pay' },
        zalopay: { text: 'Zalo Pay' },
        viettelpay: { text: 'Viettel Pay' },
        bankcard: { text: 'Thẻ ngân hàng' }
      },
      payname: '',
      payment: ''
    }
  },
  methods: {
    init () {
      const payment = this.$route.query.payment
      if (payment) {
        this.payment = payment
        return
      }
      this.setPayname(this.$route.query.payname)
    },
    choose (payname) {
      if (payname === this.payname) {
        return
      }
      this.$router.replace({ query: { payname } })
      this.setPayname(payname)
    },
    setPayname (payname) {
      this.payname = payname
      this.payment = this.paynameMap[this.payname]
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  font-size: 4vw;
  .title {
    margin: 10vw 0 5vw;
    font-size: 6vw;
  }
  .hint {
    margin: 2vw 0;
    font-size: 4vw;
  }
  .video-box {
    width: 100vw;
    overflow: hidden;
    .video {
      width: 100%;
      // transform: rotate(90deg) translate(0px, -100vw);
      // transform-origin: 0% 0%;
    }
    .no-video {
      height: 40vw;
      color: #aaa;
      display: flex;
      justify-content: center;
      align-items: center;
      .text {
        width: 80vw;
      }
    }
  }
  .btn-list {
    display: flex;
    flex-wrap: wrap;
    .btn-lite {
      display: inline-block;
      border: 1px solid #dddddd;
      border-radius: 8vw;
      margin: 2vw 0 0 2vw;
      padding: 2vw 4vw;
      display: flex;
      font-size: 4vw;
      &.active {
        background-color: #dddddd;
      }
    }
  }
}
</style>
